@import 'node_modules/polly-qubit/scss/polly-v2.mixins';
@import 'node_modules/polly-qubit/scss/variables';

.react-flow {
  &__node-custom {
    border: 1px solid $primary-neutral;
    border-radius: 5px;
    padding: 10px;
    width: 300px;
  }

  &__edge-path {
    @include add-color-mix(stroke, $primary-neutral, $black 60%);
    stroke-width: 2px;
  }

  &__node {
    max-width: 218px;
  }

  &__attribution {
    a {
      background: none;
      visibility: hidden;
    }
  }

  &__handle {
    @include add-color-mix(background-color, $primary-purple, $black 20%);
    height: 8px;
    width: 4px;
  }

  &__handle-left {
    border: 0;
    border-radius: 0 4px 4px 0;
    left: 0;
  }

  &__handle-right {
    border: 0;
    border-radius: 4px 0 0 4px;
    right: 0;
  }
}

.er-diag {
  &-table {
    border-radius: 12px;
    // change with footer shadow
    box-shadow: $footer-shadow;
    width: 218px;

    .table {
      &-header {
        @include add-color(color, $white);
        @include add-color-mix(background-color, $primary-purple, $black 20%);
        border-radius: 12px 12px 0 0;
        padding: 10px 10px 10px 14px;
      }

      &-columns {
        @include add-color(background-color, $white);
        @include add-color-mix(color, $primary-neutral, $black 40%);
        border-radius: 0 0 12px 12px;
        padding-bottom: 8px;
      }

      &-column {
        border-bottom: 1px solid color-mix(in srgb, $primary-neutral, $white 80%);
        overflow: clip;
        position: relative;

        .column-name {
          align-items: center;
          display: flex;
          grid-gap: 2px;
          padding: 10px 10px 10px 14px;
        }

        .key-icon-container {
          height: 20px;
          position: relative;
          width: 20px;

          .icon {
            height: 100%;
            position: absolute;
            width: 100%;
          }
        }
      }
    }
  }
}
