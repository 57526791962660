@import './assets/stylesheets/main';
@import url('https://assets.calendly.com/assets/external/widget.css');
@import 'swiper/swiper-bundle';
@import '../../../node_modules/polly-commons/scss/variables';
@import '@uppy/core/dist/style.css';
@import '@uppy/dashboard/dist/style.css';
@import '@reactflow/node-resizer/dist/style.css';
@import 'reactflow/dist/style.css';
@import 'react-tooltip/dist/react-tooltip.css';
@import './../../polly-commons/assets/stylesheets/vendors/primeng/elucidata/variables';
@import './../../polly-commons/assets/stylesheets/vendors/primeng/designer/overlaypanel';
@import '../src/app/modules/polly-manage/modules/omixatlas-module/components/polly-atlas/react-er-diagram/styles';

html,
body {
  height: 100%;
}

body {
  margin: 0;
}
